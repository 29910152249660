













import BlogArticle from '~/modules/storyblok/components/page-types/BlogArticle.vue';
import BlogFile from '~/modules/storyblok/components/page-types/BlogFile.vue';
import BlogCategory from '~/modules/storyblok/components/page-types/BlogCategory.vue';
import BlogCategoryFile from '~/modules/storyblok/components/page-types/BlogCategoryFile.vue';
import Breadcrumbs from '~/modules/storyblok/components/Blog/Breadcrumbs.vue';

export default {
  name: 'BlogPage',
  components: {
    BlogFile,
    BlogArticle,
    BlogCategory,
    Breadcrumbs,
    BlogCategoryFile,
  },
  layout: 'basic',
  middleware: [
    'story-url-resolver',
  ],
  async asyncData(context) {
    if (context.app.currentStory) {
      const storyType = () => {
        const type = context.app.currentStory?.content?.component;
        switch (type) {
          case 'blogArticle':
            return 'blogArticle';
          case 'blogFile':
            return 'blogFile';
          case 'blogCategory':
            return 'blogCategory';
          case 'blogSubCategory':
            return 'blogCategory';
          case 'blogSubCategory_files':
            return 'blogCategoryFile';
          default:
            return null;
        }
      };

      return { currentStory: context.app.currentStory, storyType: storyType() };
    }
    return { currentStory: null, storyType: null };
  },
  head: {},
};
