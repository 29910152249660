

































































































































































































import BlogArticleTile from '~/modules/storyblok/components/Blog/BlogArticleTile.vue';
import {
  defineComponent, nextTick, ref, useFetch, computed, useMeta, useRoute, onMounted, toRef,
} from '@nuxtjs/composition-api';
import {
  SfImage,
} from '@storefront-ui/vue';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import ProductDropdown from '~/modules/catalog/product/components/ProductDropdown.vue';
import type { Pagination } from '~/composables/types';
import { addBasePath } from '~/helpers/addBasePath';
import { useUiHelpers } from '~/composables';
import {
  getMetaInfo, getStoryMeta, getUrlMeta, getPaginationMeta,
} from '~/helpers/getMetaInfo';
import SearchBar from '~/modules/storyblok/components/Blog/SearchBar.vue';

import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import useStoryblokView from '~/modules/storyblok/composables/useStoryblokView';
import useBlogCategories from '../../composables/useBlogCategories';

export default defineComponent({
  name: 'BlogCategory',
  components: {
    CategoryPagination,
    BlogArticleTile,
    SfImage,
    SearchBar,
    ProductDropdown,
    SkeletonLoader,
  },
  layout: 'basic',
  props: {
    currentStory: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const currentStory = toRef(props, 'currentStory');
    const route = useRoute();
    const { search } = route.value.query;
    const uiHelpers = useUiHelpers();
    const categoryUUID = currentStory.value?.uuid;
    const categoryType = currentStory.value?.content?.component;
    const { mainCategories, categoryTree, load: loadCategories } = useBlogCategories();
    const { getArticleByCategoryUUID, getArticleBySubCategoryUUID } = useStoryblokView();
    const { addTags } = useCache();
    const { isAuthenticated } = useUser();

    const pagination = ref<Pagination>({
      currentPage: 0,
      totalPages: 0,
    });
    const queryPage = () => Number(uiHelpers.resolveQuery()?.page ?? 1);
    const curentPage = () => (pagination.value.totalPages !== 0 && queryPage() > pagination.value.totalPages ? 1 : queryPage());

    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      const paginationMeta = getPaginationMeta({ pagination: pagination.value, route: queryPage(), baseData: urlMeta });
      return getStoryMeta({ data: currentStory.value, baseData: paginationMeta });
    };
    useMeta(() => getMetaInfo({ page: metaInfo() }));

    const getTotalPages = (headers) => Math.ceil(Number(headers.total) / Number(headers['per-page']));

    const articles = ref([]);
    const loading = ref(true);
    const openedMainCategoryUuid = ref('');
    const toggleOpenedMainCategory = (uuid: string) => {
      openedMainCategoryUuid.value = openedMainCategoryUuid.value === uuid ? '' : uuid;
    };
    const currentMainCategory = computed(
      () => mainCategories.value.find((category) => currentStory.value?.full_slug.includes(category.full_slug)),
    );
    const currentMainCategoryFullSlug = computed(() => currentMainCategory.value?.full_slug ?? '');

    const mainCategoriesWithChildrens = computed(
      () => mainCategories.value?.filter((category) => categoryTree.value[category.full_slug] !== undefined),
    );

    const { fetch } = useFetch(async () => {
      try {
        if (!categoryUUID) throw new Error('categoryUUID not provided');

        const getArticleAttrs = {
          uuid: categoryUUID,
          params: {
            search_term: (Array.isArray(search) ? search[0] : search) ?? '',
            per_page: 10,
            page: curentPage(),
            excluding_fields: 'SEO,author,block,heroBackgraund,readMore,rich_text,subCategories,mainCategories',
          },
        };

        const { stories, headers } = categoryType === 'blogCategory'
          ? await getArticleByCategoryUUID(getArticleAttrs)
          : await getArticleBySubCategoryUUID(getArticleAttrs);

        pagination.value.currentPage = curentPage();
        pagination.value.totalPages = getTotalPages(headers);
        articles.value = stories;
        loading.value = false;
        await loadCategories();

        openedMainCategoryUuid.value = currentMainCategory.value?.uuid ?? '';
      } catch (error) {
        console.log('error', error);
      } finally {
        loading.value = false;
      }
      if (!isAuthenticated.value) {
        addTags([{ prefix: CacheTagPrefix.View, value: currentStory.value.full_slug }]);
      }
    });
    fetch();

    onMounted(() => {
      if (currentStory.value?.id) {
        useStoryblokBridge(currentStory.value.id, (evStory) => { (currentStory.value = evStory); }, { preventClicks: true });
      }
    });

    const postsContainerElement = ref<HTMLElement | null>(null);

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();

      nextTick(() => {
        postsContainerElement.value?.scrollIntoView({ block: 'start' });
        window.scrollBy(0, -200);
      });
    };
    const categoryLink = (link) => resolveUrl(`/${link}`);
    // todo: redirect to first page when first call return total data
    // if (curentPage.value > pagination.value.totalPages) {
    //   goToPage(0);
    // }

    return {
      loading,
      categoryLink,
      mainCategories,
      categoryTree,
      toggleOpenedMainCategory,
      openedMainCategoryUuid,
      currentMainCategoryFullSlug,
      queryPage,
      curentPage,
      goToPage,
      addBasePath,
      pagination,
      articles,
      postsContainerElement,
      mainCategoriesWithChildrens,
      resolveUrl,
    };
  },
  head: {},
});
