














import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import { Breadcrumb } from '~/modules/catalog/types';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

export default defineComponent({
  components: { SfBreadcrumbs },
  props: {
    currentStory: {
      type: Object,
      default: null,
    },
  },
  setup({ currentStory }) {
    const { localePath } = useContext();
    const getStoryType = computed(() => {
      const currentStoryComponent = currentStory?.content?.component;

      switch (currentStoryComponent) {
        case 'blogArticle':
        case 'blogFile': {
          return 'article';
        }
        case 'blogSubCategory':
        case 'blogSubCategory_files': {
          return 'sub-category';
        }
        case 'blogCategory': {
          return 'category';
        }
        default: {
          return 'index';
        }
      }
    });

    const basicBreadcrumbs: Breadcrumb[] = [
      {
        text: '⌂ Strona główna',
        link: localePath('/'),
      },
      {
        text: 'Blog',
        link: '/blog',
      },
    ];

    const breadcrumbs = computed((): Breadcrumb[] => (getStoryType.value !== 'index'
      ? [
        ...basicBreadcrumbs,
        {
          text: currentStory?.name ?? '',
          link: resolveUrl(`/${currentStory?.full_slug}`) ?? '',
        },
      ]
      : basicBreadcrumbs));

    const getBreadcrumbsSchema = () => (JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.value.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb.text,
        item: `https://agrosimex.pl${breadcrumb.link}`,
      })),
    }));

    return {
      getBreadcrumbsSchema,
      getStoryType,
      breadcrumbs,
    };
  },
});
