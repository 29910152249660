

















import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'BackButton',
  components: {
    SfImage,
  },
  setup() {
    const router = useRouter();
    const pushToBack = () => router.back();

    return {
      pushToBack,
      addBasePath,
    };
  },
});
